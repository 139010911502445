import React from 'react';
import PropTypes from 'prop-types';
import RightsWrapper from '../../sessionProvider/RightsWrapper';
import Date from '../../general/Date';
import InputTextArea from '../../general/InputTextArea';
import EmployeesSelect from '../../project/EmployeesSelect';
import InfoAttributeGroup from '../../general/InfoAttributeGroup';
import EmailLink from '../../general/EmailLink';
import PhoneLink from '../../general/PhoneLink';
import ChannelOptions from '../../project/ChannelOptions';
import {RequestDetail} from '../../../constants/propTypesDefinitions';
import {RIGHTS} from '../../../constants/Rights';
import {Trans, t} from '@lingui/macro';
import {createFetchRequestEdit} from '../../../backend/apiCalls';
import {GLOBAL_DATA} from '../../../constants/globalData';
import {merge} from '../../../lib/object';
import {isRequestSend, isTradeSell} from '../../../lib/project';
import {formatString} from '../../../lib/formatting';
import withDataHOC from '../../dataProvider/withDataHOC';
import Link from '../../navigation/Link';
import { ROUTES, QUERY_PARAMS } from '../../../constants/navigation';
import UserLink from '../../general/UserLink';
import CustomerLink from '../../general/CustomerLink';
import InputText from '../../general/InputText';
import UserSelectByCustomer from '../../project/UserSelectByCustomer';
import { notification } from 'antd';

/**
 * @fero
 */

class RequestDetailsInfo extends React.PureComponent {
    static propTypes = {
        requestDetails: RequestDetail.isRequired,
    };

    onDealerChange = (dealerId) => {
        this.editRequest({id_dealer: dealerId});
    };

    onUserChange = (userId) => {
        this.editRequest({id_user: userId});
    };

    onCustomerNoticeChange = (newNotice) => {
        this.editRequest({customer_notices: newNotice});
    };

    onDealerNoticeChange = (newNotice) => {
        this.editRequest({dealer_notices: newNotice});
    };

    onCustomerReferenceChange = (newRef) => {
        this.editRequest({customer_reference: newRef});
    };

    onArgsChange = (newArgs) => {
        this.editRequest({args: newArgs});
    };

    editRequest = (queryParams) => {
        const {requestDetails} = this.props;
        const reload = this.props[GLOBAL_DATA.RELOAD_DATA];
        const fetchHandler = this.props[GLOBAL_DATA.FETCH_HANDLER];
        fetchHandler(createFetchRequestEdit(),
            merge({id: requestDetails.id}, queryParams),
            null,
            () => reload([GLOBAL_DATA.REQUEST_DETAILS]),
            (error) => {
                notification['error']({ 
                    message: error.message,
                    duration: 10,
                });
            }
        );
    };

    render() {
        const {requestDetails = {}} = this.props;
        const customerChecked = requestDetails.customer != null ? requestDetails.customer : {};
        const userChecked = requestDetails.user != null ? requestDetails.user : {};
        const dealer = requestDetails.dealer;
        const dealerChecked = dealer != null ? dealer : {};
        return <div className="d-flex flex-wrap justify-content-center">
            <RightsWrapper from={RIGHTS.MARKETING}>
                <InfoAttributeGroup
                    attributes={[
                        {
                            title: <Trans>Organizácia: </Trans>,
                            value: <CustomerLink
                                customerId={customerChecked.id}
                                customerName={customerChecked.name}
                            />
                        },
                        {
                            title: <Trans>IČO: </Trans>,
                            value: formatString(customerChecked.ico)
                        },
                        {
                            title: <Trans>IČ DPH: </Trans>,
                            value: formatString(customerChecked.ic_dph)
                        },
                        {
                            title: <Trans>Zodpovedný obchodník: </Trans>,
                            value: customerChecked.salesman
                        },
                        {
                            title: <Trans>Logista: </Trans>,
                            value: customerChecked.salesman2
                        },
                        {
                            title: <Trans>Vybavuje: </Trans>,
                            value: [
                                <RightsWrapper key="manager" from={RIGHTS.SUPERVISOR}>
                                    <EmployeesSelect
                                        value={dealerChecked.id}
                                        onChange={this.onDealerChange}
                                    />
                                </RightsWrapper>,
                                <RightsWrapper key="dealer" to={RIGHTS.DEALER}>
                                    {dealerChecked.fullname != null ? dealerChecked.fullname : null}
                                </RightsWrapper>
                            ]
                        },
                        {
                            value: <ChannelOptions 
                                name={customerChecked.channel} 
                                options={requestDetails.options} 
                                value={requestDetails.args} 
                                onChange={this.onArgsChange}
                            />
                        }
                    ]}
                />
                <InfoAttributeGroup
                    attributes={[
                        {
                            title: <Trans>Kontakt: </Trans>,
                            value: !isRequestSend(requestDetails) ? 
                            <UserSelectByCustomer
                                customerId={customerChecked.id}
                                value={userChecked.id}
                                onChange={this.onUserChange}
                                allowClear={true}
                            /> :
                            <UserLink
                                userId={userChecked.id}
                                userName={userChecked.fullname}
                            />
                        },
                        {
                            title: <Trans>Email: </Trans>,
                            value: <EmailLink email={userChecked.e_mail}/>
                        },
                        {
                            title: <Trans>Telefón: </Trans>,
                            value: <PhoneLink phone={userChecked.phone}/>
                        },
                        {
                            title: <Trans>Odoslané: </Trans>,
                            value: <Date dateString={requestDetails.sent_at}/>
                        },
                        {
                            title: <Trans>Cenová hladina: </Trans>,
                            value: requestDetails.id_price_level != null ? 
                                <CustomerLink
                                    customerId={requestDetails.id_price_level}
                                    customerName={requestDetails.price_level}
                                /> : null,
                            isHidden: (requestDetails.id_price_level == null)
                        }
                    ]}
                />
                <InfoAttributeGroup
                    attributes={[
                        {
                            title: <Trans>Číslo dopytu u zákazníka:</Trans>,
                            value: isRequestSend(requestDetails) ? 
                                <h4>{formatString(requestDetails.customer_reference)}</h4> :
                                <InputText
                                    onChange={this.onCustomerReferenceChange}
                                    value={requestDetails.customer_reference}
                                />,
                            isHidden: !isTradeSell(requestDetails.trade),
                        },
                        {
                            title: <Trans>Poznámka: </Trans>,
                            value: !isRequestSend(requestDetails) ?
                                <InputTextArea
                                    size="small"
                                    value={requestDetails.customer_notices}
                                    onChange={this.onCustomerNoticeChange}
                                /> : <pre>{requestDetails.customer_notices}</pre>,
                        },
                        {
                            title: <Trans>Interná poznámka: </Trans>,
                            value: <InputTextArea
                                size="small"
                                value={requestDetails.dealer_notices}
                                onChange={this.onDealerNoticeChange}
                            />
                        }
                    ]}
                />
            </RightsWrapper>
            <RightsWrapper to={RIGHTS.DISTRIBUTOR}>
                {dealer != null ? <InfoAttributeGroup
                    attributes={[
                        {
                            title: <Trans>Predajca: </Trans>,
                            value: dealerChecked.fullname
                        },
                        {
                            title: <Trans>Email: </Trans>,
                            value: <EmailLink email={dealerChecked.e_mail}/>
                        },
                        {
                            title: <Trans>Telefón: </Trans>,
                            value: <PhoneLink phone={dealerChecked.phone}/>
                        },
                        {
                            title: <Trans>Cenová hladina: </Trans>,
                            value: requestDetails.price_level,
                            isHidden: (requestDetails.id_price_level == null)
                        }
                    ]}
                /> : null}
                <InfoAttributeGroup
                    attributes={[
                        {
                            title: <Trans>Vaše číslo dopytu:</Trans>,
                            value: <h4>{formatString(requestDetails.customer_reference)}</h4>
                        },
                        {
                            title: <Trans>Odoslané: </Trans>,
                            value: <Date dateString={requestDetails.sent_at}/>
                        },
                        {
                            title: <Trans>Poznámka: </Trans>,
                            value: !isRequestSend(requestDetails) ?
                                <InputTextArea
                                    size="small"
                                    value={requestDetails.customer_notices}
                                    onChange={this.onCustomerNoticeChange}
                                /> : <pre>{requestDetails.customer_notices}</pre>,
                        },
                    ]}
                />
            </RightsWrapper>
        </div>
            ;
    }

}

export default withDataHOC([GLOBAL_DATA.RELOAD_DATA, GLOBAL_DATA.FETCH_HANDLER])(RequestDetailsInfo);